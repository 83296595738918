@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url("https://fonts.googleapis.com/css?family=Plus+Jakarta+Sans:200,300,regular,500,600,700,800&display=swap");
@import "reset.css";


body {
  font-family: "Plus Jakarta Sans", sans-serif;
}

::selection {
  background-color: #000;
  color: #fff;
}


.shadow-lg {
  transition: 100ms ease-in;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


/* Header Styles */
.header {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.2);
  border: 1px solid hsla(0, 0%, 100%, 0.18);
}

.header-mob {
  background: #fff;
  border: 1px solid hsla(0, 0%, 100%, 0.18);
}

.header-mob-bg {
  background: #fff;
  border: 1px solid hsla(0, 0%, 100%, 0.18);
}

.running-underline {
  position: relative;
  display: inline-block;
}

.running-underline::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: currentColor;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.running-underline:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.active::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media screen and (max-width: 768px) {
  .header-mob {
    border-radius: 40px 40px 0 0;
    border: none;
  }

  .header-mob-bg {
    border-radius: 0 0 40px 40px;
    background: #fff;
  }
}


/* Preloade Style */
.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000;
}

.preloader-content {
  text-align: center;
}

.running-line {
  height: 4px;
  background-color: #235AF3;
  transition: width 0.5s ease;
  margin-bottom: 10px;
  width: 400px;
}

.percentage {
  font-size: 3rem;
  color: #000;
}


/* Cursor Style */
.custom-cursor {
  position: fixed;
  pointer-events: none;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out, background-color 0.3s ease-out;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.custom-cursor.is-hovering {
  display: flex;
  background-color: #235AF3;
  transform: translate(-50%, -50%) scale(2);
}

.inner-cursor {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-cursor-hover {
  cursor: none; /* Hide the default cursor */
}
